import { whiteboardCreated, whiteboardOpened } from 'features/layout/features/content/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, openModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';
import { selectWhiteboardOpen } from 'features/whiteboard/whiteboardSlice';
import { usePermissions } from 'features/permissions/usePermissions';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

const WhiteboardE2eeConfirmationModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);

  const whiteboardOpen = useAppSelector(selectWhiteboardOpen);

  const { hasPermissions } = usePermissions();

  const isContentLibraryEnabled = useFeatureFlag('contentLibrary');
  const canPresentContentLibrary = hasPermissions(PermissionTypes.presentContentLibrary);

  const isContentLibraryFeatureAvailable = isContentLibraryEnabled && canPresentContentLibrary;

  const handleOk = () => {
    if (modalData.flow === 'create') {
      if (!isContentLibraryFeatureAvailable && whiteboardOpen) {
        dispatch(openModal('createNewWhiteboardConfirmation'));
      } else {
        dispatch(whiteboardCreated(modalData.options));
      }
    } else {
      dispatch(whiteboardOpened(modalData.whiteboardId));
      dispatch(closeModal('whiteboardE2eeConfirmation'));
    }
  };

  const handleCancel = () => {
    dispatch(closeModal('whiteboardE2eeConfirmation'));
  };

  return (
    <ConfirmationDialog
      open
      maxWidth="sm"
      title={t('whiteboard_e2ee_confirmation.title')}
      description={t('whiteboard_e2ee_confirmation.description')}
      cancelText={t('whiteboard_e2ee_confirmation.btn_cancel_label')}
      okText={t('whiteboard_e2ee_confirmation.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      preventClose
      hideCloseButton
    />
  );
};

export default WhiteboardE2eeConfirmationModal;
